import React, { useState } from "react";
import { Box, Center, Flex } from "@chakra-ui/react";

import { getCategories, getItems } from "./services/categoryService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const categoryTypes = [{ type: "Desserts" }];

function Dessert() {
  const { t, i18n } = useTranslation("global");
  const [currentCategoryItems, setCurrentCategoryItems] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const { data: resp } = useQuery(["categories"], getCategories, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { data: menuItemResp } = useQuery(["items"], getItems, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const renderCategoryItems = (categoryName) => {
    const filteredItems = menuItemResp?.data?.filter(
      (item) => item.categoryName === categoryName
    );
    setCurrentCategoryItems(filteredItems);
    setSelectedCategoryName(categoryName);
  };

  return (
    <>
      {categoryTypes.map((categoryType, index) => (
        <Box
          minH="34vh"
          sx={{
            "@media (max-width: 425px)": {
              minH: "20vh",
            },
            "@media (max-width: 405px)": {},
            "@media (max-width: 380px)": {},
            "@media (max-width: 340px)": {},
          }}
          key={index}
          w="100%"
          p="0"
          m="0"
        >
          <Flex justifyContent="space-between" w="100%" flexDirection="column">
            {resp?.data
              ?.filter(
                (category) => category.categoryType === categoryType.type
              )
              ?.map((category, index) => (
                <NavLink to={`/dessert/${category.name}`} key={index}>
                  <Box
                    key={index}
                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                    borderRadius={"30px"}
                 
                    w="100%"
                    h={"140px"}
                    m="16px 0"
                    // backgroundSize={"cover"}
                    // bgPosition="3px 45%"
                    backgroundSize={category.name === "Dessert" && "cover"}
                    bgPosition={
                      category.name === "Dessert" ? "-10px 63%" : "-3px 45%"
                    }
                    cursor={"pointer"}
                    backgroundImage={`url(https://api.menu.shaurma1.az/Images/${category.photoPath})`}
                    onClick={() => renderCategoryItems(category.name)}
                  >
                    <Center
                      backgroundColor="rgba(0, 0, 0, 0.4)"
                      color="#FFFFFF"
                      borderRadius="30px"
                      fontSize="28px"
                      textTransform="uppercase"
                      h="100%"
                      zIndex="3"
                      textAlign="center"
                      textShadow=" 1px 1px 3px rgba(0, 0, 0, 0.35)"
                    >
                      {t(category.name)}
                    </Center>
                  </Box>
                </NavLink>
              ))}
          </Flex>
        </Box>
      ))}
    </>
  );
}

export default Dessert;
