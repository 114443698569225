import React, { useState, useMemo } from "react";
import { Box, Center, Flex } from "@chakra-ui/react";
import { getCategories, getItems } from "./services/categoryService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const categoryTypes = [{ type: "Menu" }];

const MemoizedCategoryBox = React.memo(
  ({ category, renderCategoryItems, t }) => {
    const backgroundSize =
      // category.name === "Pizza"
      //   ? "auto 220%"
      //   // : category.name === "Souslar"
      //   // ? "auto 290%"
      //   :
      "cover";

    const bgPosition =
      category.name === "Şorbalar"
        ? "-10px 62%"
        : category.name === "Bükmələr"
        ? "-10px 40%"
        : category.name === "Shaurma"
        ? "-10px 42%"
        : category.name === "Qəlyanaltılar"
        ? "-30px 45%"
        : category.name === "Pizza"
        ? "center 33%"
        : category.name === "Pide"
        ? "-10px 45%"
        : category.name === "Lahmacun"
        ? "-20px 40%"
        : category.name === "Kombo Menyu"
        ? "10px 50%"
        : category.name === "Souslar"
        ? "center 40%"
        : "-10px 28%";

    return (
      <Link to={`/menu/${category.name}`} key={category.name}>
        <Box
          boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
          borderRadius="30px"
          w="100%"
          h="140px"
          m="16px 0"
          backgroundSize={backgroundSize}
          bgPosition={bgPosition}
          cursor="pointer"
          backgroundImage={`url(https://api.menu.shaurma1.az/Images/${category.photoPath})`}
          onClick={() => renderCategoryItems(category.name)}
        >
          <Center
            backgroundColor="rgba(0, 0, 0, 0.4)"
            color="#FFFFFF"
            borderRadius="30px"
            fontSize="28px"
            textTransform="uppercase"
            h="100%"
            zIndex="3"
            p="30px"
            textAlign="center"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.35)"
          >
            {t(category.name)}
          </Center>
        </Box>
      </Link>
    );
  }
);

function MenuP() {
  const { t } = useTranslation("global");
  const [currentCategoryItems, setCurrentCategoryItems] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const { data: resp } = useQuery(["categories"], getCategories, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { data: menuItemResp } = useQuery(["items"], getItems, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const renderCategoryItems = (categoryName) => {
    const filteredItems = menuItemResp?.data?.filter(
      (item) => item.categoryName === categoryName
    );
    setCurrentCategoryItems(filteredItems);
    setSelectedCategoryName(categoryName);
  };

  return (
    <>
      {categoryTypes.map((categoryType, index) => (
        <Box key={index} w="100%" p="0" m="0" minH="34vh">
          <Flex justifyContent="space-between" w="100%" flexDirection="column">
            {resp?.data
              ?.filter(
                (category) => category.categoryType === categoryType.type
              )
              ?.map((category, index) => (
                <MemoizedCategoryBox
                  key={category.name}
                  category={category}
                  renderCategoryItems={renderCategoryItems}
                  t={t}
                />
              ))}
          </Flex>
        </Box>
      ))}
    </>
  );
}

export default MenuP;
