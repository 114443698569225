import { httpClient } from "../helpers/httpClient";


export const getCategories = () => {
  return httpClient.get("Category");
};

export const getItems = () => {
    return httpClient.get("MenuItem");
  };
  