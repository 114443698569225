import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import global_en from "./translations/en/global.json";
import global_ru from "./translations/ru/global.json";
import global_az from "./translations/az/global.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

let persistor = persistStore(store);

i18next.init({
  interpolation: { escapeValue: false },
  lng: "az", //default
  fallbackLng: "az",
  resources: {
    az: {
      global: global_az,
    },
    ru: {
      global: global_ru,
    },
    en: {
      global: global_en,
    },
  },
});

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
