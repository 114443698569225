import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./assets/Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tCoffeImg from "./assets/img/TCoffe.jpg";

const CategoryItems = React.memo(({ items, categoryName }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { t, i18n } = useTranslation("global");
  const [modal, setModal] = useState(false);
  const [selectedPrices, setSelectedPrices] = useState({});

  const handleOptionChange = useCallback((e, itemId) => {
    setSelectedPrices((prevState) => ({
      ...prevState,
      [itemId]: e.target.value,
    }));
  }, []);

  const sortedOptions = (item) => {
    if (
      item.categoryName === "Pizza" ||
      item.categoryName === "Pide" ||
      item.categoryName === "Shaurma"
    ) {
      return item.menuItemOptions.slice().sort((a, b) => a.price - b.price);
    }
    return item.menuItemOptions;
  };

  const handleTCoffee = (item) => {
    setSelectedItem(item);
    setModal(true);
  };
  const handleImageClick = useCallback((item) => {
    setSelectedItem(item);
    setModal(true);
  }, []);

  const toggleModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  // console.log(items);

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      {categoryName === "Türk Qəhvəsi" ? (
        <VStack
          fontFamily="Comfortaa, Arial"
          mt="8px"
          gap="4px"
          alignItems="start"
          width="100%"
        >
          <Box
            w="100%"
            mb="8px"
            sx={{
              "@media (min-width: 200px) and (max-width: 299px)": {
                height: "150px",
              },
              "@media (min-width: 300px) and (max-width: 349px)": {
                height: "170px",
              },
              "@media (min-width: 450) and (max-width: 499px)": {
                height: "280px",
              },
              "@media (min-width: 500px) and (max-width: 559px)": {
                height: "300px",
              },
              "@media (min-width: 560px)": {
                height: "326px",
              },
            }}
          >
            <Image
              boxShadow="0px 2px 3px 3px rgba(0, 0, 0, 0.10)"
              // maxH="326px"
              // h="326px"
              // maxH="326px"
              // h="270px"
              // h="100%"

              sx={{
                height: "100%",
                objectFit: "cover",
              }}
              w="100%"
              borderRadius="28px"
              src={tCoffeImg}
              onClick={() => handleTCoffee(tCoffeImg)}
              cursor="pointer"
            />
          </Box>
          {items?.map((item, index) => (
            <>
              <Flex mt="8px" alignItems="center">
                <Box
                  // textTransform="capitalize"

                  fontSize="18px"
                  fontWeight="600"
                >
                  {t(`name.${item.name}`)}
                </Box>
              </Flex>

              <Text color={"#f7906c"} fontSize="22px">
                {t(`cost`)}: {item.defaultPrice} ₼
              </Text>
              {index < items?.length - 1 && (
                <Divider
                  mb="12px"
                  borderColor={"#f7906c61"}
                  borderWidth="1px"
                />
              )}
            </>
          ))}
        </VStack>
      ) : (
        items?.map((item, index) => (
          <VStack
            fontFamily="Comfortaa, Arial"
            mt="8px"
            key={item.id}
            gap="4px"
            alignItems="start"
            width="100%"
          >
            {item?.photoPath && (
              <Box
                w="100%"
                sx={{
                  "@media (min-width: 200px) and (max-width: 299px)": {
                    height: "200px !important",
                  },
                  "@media (min-width: 300px) and (max-width: 350px)": {
                    height: "200px !important",
                  },
                  "@media (min-width: 350px) and (max-width: 400px)": {
                    height: "240px !important",
                  },
                  "@media (min-width: 400px) and (max-width: 500px)": {
                    height: "280px !important",
                  },
                  "@media (min-width: 500px) and (max-width: 560px)": {
                    height: "300px",
                  },
                  "@media (min-width: 560px)": {
                    height: "326px",
                  },
                }}
              >
                <Image
                  loading="eager"
                  boxShadow="0px 2px 3px 3px rgba(0, 0, 0, 0.10)"
                  height="100%"
                  objectFit="contain"
                  bg={
                    categoryName === "Souslar" ||
                    categoryName === "Dessert" ||
                    categoryName === "Limonadlar" ||
                    categoryName === "Milkshakes" ||
                    categoryName === "Souslar" ||
                    categoryName == "Şorbalar"
                      ? "white"
                      : "#f7f7f7"
                  }
                  w="100%"
                  borderRadius="28px"
                  src={`https://api.menu.shaurma1.az/Images/${item?.photoPath}`}
                  onClick={() => handleImageClick(item)}
                  cursor="pointer"
                />
              </Box>
            )}
            <HStack mt="8px" alignItems="center">
              <Text fontSize="18px" fontWeight="600">
                {t(`name.${item.name}`)}
              </Text>
              {item?.isNew && (
                <Text
                  ml="12px"
                  padding="4px 12px"
                  borderRadius="12px"
                  bg="red"
                  color="white"
                >
                  {t("new")}
                </Text>
              )}
            </HStack>
            {item?.description && (
              <Text
                textTransform="capitalize"
                lineHeight="22px"
                fontWeight="500"
                fontSize="14px"
                color="#716b68"
                letterSpacing="0.4px"
              >
                {categoryName === "Sıxılmış Meyvə Şirələri" ||
                categoryName == "Sərinləşdirici içkilər"
                  ? item.description
                  : t(`description.${item.description}`)}
              </Text>
            )}

            {item?.menuItemOptions && item?.menuItemOptions?.length > 0 ? (
              <VStack
                mt="12px"
                alignItems="start"
                flexDirection="column-reverse"
                w="100%"
              >
                <Text whiteSpace="nowrap" color={"#f7906c"} fontSize="24px">
                  {t(`cost`)}:{" "}
                  {selectedPrices[item.id] || sortedOptions(item)[0].price} ₼
                </Text>
                <Box>
                  <Select
                    whiteSpace="wrap"
                    minW="20%"
                    value={
                      selectedPrices[item.id] || sortedOptions(item)[0].price
                    }
                    onChange={(e) => handleOptionChange(e, item.id)}
                  >
                    {sortedOptions(item).map((option) => (
                      <option key={option.id} value={option.price}>
                        {option.size}
                      </option>
                    ))}
                  </Select>
                </Box>
              </VStack>
            ) : (
              <Text color={"#f7906c"} fontSize="24px">
                {t(`cost`)}: {item.defaultPrice} ₼
              </Text>
            )}

            {index < items?.length - 1 && (
              <Divider mb="12px" borderColor={"#f7906c61"} borderWidth="1px" />
            )}
          </VStack>
        ))
      )}

      {modal && selectedItem && (
        <div className="modal">
          <div
            onClick={toggleModal}
            style={{ color: "red ! important" }}
            className="overlay"
          ></div>
          <div className="modal-content">
            {categoryName === "Türk Qəhvəsi" ? (
              <>
                <Image
                  src={tCoffeImg}
                  w="100%"
                  h={"auto"}
                  objectFit="default"
                />
                <Button
                  position="absolute"
                  top="2%"
                  right="7%"
                  padding="5px 7px"
                  height="30px"
                  width="30px"
                  border-radius="50%"
                  transition=".4s"
                  border="2px dashed gray"
                  borderStyle="ridge"
                  bgColor={"#e0e0e0"}
                  _hover={{ backgroundColor: "white" }}
                  onClick={toggleModal}
                >
                  <FontAwesomeIcon
                    fontSize="20px"
                    color="black"
                    icon={faXmark}
                  />
                </Button>
              </>
            ) : (
              <>
                <Image
                  className="modal-image"
                  src={`https://api.menu.shaurma1.az/Images/${selectedItem?.photoPath}`}
                  alt={selectedItem?.name}
                  w="100%"
                  h="360px"
                  bg={
                    categoryName === "Souslar" ||
                    categoryName === "Dessert" ||
                    categoryName === "Limonadlar" ||
                    categoryName === "Milkshakes" ||
                    categoryName == "Çay Dəsti" ||
                    categoryName == "Şorbalar"
                      ? "white"
                      : "#f7f7f7"
                  }
                  objectFit="contain"
                />
                <Text
                  fontWeight="600"
                  color="#F3F3F3"
                  p="12px 0 4px 8px"
                  fontSize="16px"
                  bg="#3a3a3a"
                >
                  {selectedItem?.name}
                </Text>

                <button className="close-modal" onClick={toggleModal}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </Flex>
  );
});

export default CategoryItems;
