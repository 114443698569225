import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Layout from "./Layout";
import MenuP from "./Menu";
import CategoryItemsPage from "./CategoryItemsPage";
import Dessert from "./Dessert";
import Bar from "./Bar";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/menu" />} />
            <Route path="/menu" element={<MenuP />} />
            <Route
              path="/menu/:categoryName/:type?"
              element={<CategoryItemsPage />}
            />
            <Route path="/dessert" element={<Dessert />} />

            <Route path="/bar" element={<Bar />} />

            <Route
              path="/dessert/:categoryName"
              element={<CategoryItemsPage />}
            />

            <Route path="/bar/:categoryName" element={<CategoryItemsPage />} />
            {/* 
            <Route
              path="/menu/:categoryName/:type?"
              element={<CategoryItemsPage />}
            />
            <Route path="/bar/:categoryName" element={<CategoryItemsPage />} />
            <Route
              path="/dessert/:categoryName"
              element={<CategoryItemsPage />}
            /> */}
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
