import React, { useState } from "react";
import {
  Box,
  Center,
  Flex,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import { getCategories, getItems } from "./services/categoryService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const categoryTypes = [{ type: "Bar" }];

function Bar() {
  const { t, i18n } = useTranslation("global");
  const [currentCategoryItems, setCurrentCategoryItems] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const { data: resp } = useQuery(["categories"], getCategories, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const { data: menuItemResp } = useQuery(["items"], getItems, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const renderCategoryItems = (categoryName) => {
    const filteredItems = menuItemResp?.data?.filter(
      (item) => item.categoryName === categoryName
    );
    setCurrentCategoryItems(filteredItems);
    setSelectedCategoryName(categoryName);
  };

  const { colorMode } = useColorMode();

  return (
    <>
      {categoryTypes.map((categoryType, index) => (
        <Box
          key={index}
          w="100%"
          p="0"
          m="0"
          minH="34vh"
          // border="1px solid white"
        >
          <Flex justifyContent="space-between" w="100%" flexDirection="column">
            {resp?.data
              ?.filter(
                (category) => category.categoryType === categoryType.type
              )
              ?.map((category, index) => (
                <NavLink to={`/bar/${category.name}`} key={index}>
                  <Box
                    border={
                      category?.photoPath
                        ? "none"
                        : colorMode === "dark"
                        ? "1px solid white"
                        : "none"
                    }
                    key={index}
                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                    borderRadius={"30px"}
                    w="100%"
                    h={"140px"}
                    m="16px 0"
                    // backgroundSize={"cover"}
                    // bgPosition="3px 45%"

                    backgroundSize={
                      category.name === "Milkshakes" ? "103%" : "cover"
                    }
                    bgPosition={
                      category.name === "Çay Dəsti"
                        ? "36% 15%"
                        : category.name === "Türk Qəhvəsi"
                        ? "center"
                        : category.name === "Sərinləşdirici içkilər"
                        ? "center 46%"
                        : category.name === "Limonadlar"
                        ? "center 30%"
                        : category.name === "Milkshakes"
                        ? "-40% 29%"
                        : "10px 0%"
                    }
                    cursor={"pointer"}
                    backgroundImage={`url(https://api.menu.shaurma1.az/Images/${category?.photoPath})`}
                    // backgroundImage={`url(https://localhost:7152/Images/${category?.photoPath})`}
                    onClick={() => renderCategoryItems(category.name)}
                  >
                    <Center
                      backgroundColor="rgba(0, 0, 0, 0.4)"
                      color="#FFFFFF"
                      borderRadius="30px"
                      fontSize="28px"
                      textTransform="uppercase"
                      h="100%"
                      zIndex="3"
                      textAlign="center"
                      textShadow=" 1px 1px 3px rgba(0, 0, 0, 0.35)"
                    >
                      {t(category.name)}
                    </Center>
                  </Box>
                </NavLink>
              ))}
          </Flex>
        </Box>
      ))}
    </>
  );
}

export default Bar;
