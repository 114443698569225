import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";
import CategoryItems from "./CategoryItems";
import { useQuery } from "react-query";
import { getItems } from "./services/categoryService";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import bgImage1 from "./assets/img/Margarita.jpg";
import bgImage3 from "./assets/img/PolloFunghi.jpg";

const bgImages = [bgImage1, bgImage3];

const CategoryItemsPage = () => {
  const { categoryName, type } = useParams();
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const { data: menuItemResp } = useQuery(["items"], getItems, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (error) => console.error("Error fetching items:", error),
  });

  const [selectedType, setSelectedType] = useState(type || "");

  const pizzaTypes = ["22 cm", "30 cm"];

  // const currentCategoryItems = menuItemResp?.data?.filter(
  //   (item) =>
  //     item.categoryName.trim() === categoryName.trim() &&
  //     (selectedType ? item.type === selectedType : true)
  // );

  const currentCategoryItems = menuItemResp?.data
    ?.filter(
      (item) =>
        item?.categoryName?.trim()?.toLowerCase() ===
          categoryName?.trim()?.toLowerCase() &&
        (selectedType
          ? item.type?.toLowerCase() === selectedType?.toLowerCase()
          : true)
    )
    ?.sort((a, b) => {
      // Check if 'PIZZA MARGHERITA' should come first
      if (a.id === 151) return -1;
      if (b.id === 151) return 1;

      if (a.id === 58) return 1;
      if (b.id === 58) return -1;

      return 0;
    });

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    navigate(`/menu/${categoryName}/${type}`);
  };
  const handleBackToTypes = () => {
    setSelectedType("");
    navigate(`/menu/${categoryName}`);
  };

  const handleBack = () => {
    if (categoryName === "Pizza") {
      setSelectedType("");
    }
    navigate(-1);
  };

  // console.log(menuItemResp);
  return (
    <Box w="100%" p="0" m="0">
      <Box
        onClick={handleBack}
        position={"fixed"}
        border="none"
        borderRadius="50%"
        padding="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        boxShadow=" 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)"
        zIndex="10"
        top="12px "
        left="9px "
        cursor="pointer"
        boxSize="40px"
        bgColor="#FFFFFF"
      >
        <FontAwesomeIcon
          fontWeight="900"
          color="#4c4c4c"
          fontSize="17px"
          icon={faArrowLeft}
        />
      </Box>
      <Text
        textTransform="uppercase"
        fontSize="20px"
        fontWeight="600"
        mb="4px"
        mt="20px"
        color="#716b68"
      >
        {t(categoryName)} {selectedType}
      </Text>
      <Divider mb="8px" mt="4px" borderColor={"#f7906c61"} borderWidth="1px" />
      <CategoryItems categoryName={categoryName} items={currentCategoryItems} />
    </Box>
  );
};

export default CategoryItemsPage;
